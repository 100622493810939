/* App.js */
main {
    min-height: 80vh;
}

body {
    background: #f1efef !important;
    overflow: hidden !important;
}

.use-max-height {
    min-height: calc(100dvh - 70px) !important;
}

#content-wrapper {
    overflow-y: scroll !important;
    height: calc(100dvh - 70px) !important;
}

.navbar-bg {
    color: black !important;
    background: white !important;
    padding: 10px 0 !important;
    border-bottom: 1px solid lightgrey;
}

.navbar-search-input {
    cursor: pointer;
    width: 6rem !important;
    padding: .40rem 1rem !important;
    font-family: serif;
    border-radius: 25rem !important;
    opacity: .5;
    transition: width .1s ease-in-out, background .1s ease-in, padding .1s ease-in !important;
}

.navbar-search-input:hover {
    background: #d8d8d8 !important;
}

.navbar-search-input:focus {
    padding: .40rem 1.3rem !important;
    box-shadow: none !important;
    opacity: 1;
    cursor: auto;
    background: #dad8d8 !important;
    width: 15rem !important;
}

.nav-link {
    color: #464343 !important;
}

/* Product.js */
h3 {
    padding: 1rem 0;
}

/* Rating.js */
.rating span {
    margin: 0.1rem;
}

/* CartScreen.js */
h1 {
    font-size: 1.8rem;
    padding: 1rem 0;
}

h2 {
    font-size: 1.4rem;
    padding: 0.5rem 0;
}

/* HomeScreen.js (Carousel) */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: flex;
}

.carousel-caption {
    position: absolute;
    top: 0;
    color: white;
}

.carousel img {
    display: block;
    height: 300px;
    padding: 30px;
    border-radius: 50%;
    margin: 50px auto 40px;
}

.carousel a {
    margin: 0 auto;
}

@media (max-width: 900px) {
    .carousel-caption h2 {
        font-size: 2.5vw;
        color: white;
    }
}

.app-title {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.3rem !important;
    font-weight: bold;
    color: #1976d2 !important;
    text-align: center;
    letter-spacing: 0;
}

.my-font-family {
    font-family: inherit !important;
}

.sagoe-font-family {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.tab-item {
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: background-color .1s ease-in;
}

.tab-item:hover {
    background-color: #f1efef;
}

.tab-item-text {
    padding: .75rem 0;
    border-bottom: .25rem solid transparent;
}

.tab-item-text.active {
    border-bottom: .25rem solid #5b8f5e;
    border-radius: 3px;
}

.text-muted {
    color: #a49e9e !important;
}

.blue-icon {
    color: #8e8f90 !important;
}

.blue-icon:hover {
    color: #1976d2 !important;
}

.red-icon {
    color: #8e8f90 !important;
}

.red-icon:hover, .red-icon.active {
    color: #d32f2f !important;
}

.shadow-1 {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%),
    0 2px 4px -1px rgb(0 0 0 / 20%);
}

.shadow-2 {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

.test {
    color: #eaecec
}

.user-dialog {
    padding: .5rem .5rem;
    cursor: pointer;
    transition: background-color .1s ease-in;
}

.user-dialog:hover {
    background-color: #f1efef;
}

.dialog-header {
    display: flex;
    padding: .5rem;
}

.dialog-header-image {
    margin-top: .3rem;
}

.dialog-header-info {
    padding-left: .25rem;
}

.dialog-header-info-subtitle {
    word-break: break-word;
}

.unread-messages-box {
    border-radius: 50%;
    font-size: .8rem;
    padding: .15rem 0 !important;
    text-align: center;
    background-color: #1976d2;
    color: white;
}

.cover-image {
    width: 100% !important;
    height: 200px !important;
}

.profile-image {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
}

.locale-autocomplete-input input {
    color: white !important;
}

.locale-autocomplete-input fieldset {
    border: 1px solid rgba(255, 255, 255, .25);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.locale-autocomplete-input:hover fieldset {
    border-color: rgba(255, 255, 255, .25) !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.site-main-background-color {
    background-color: #0f7e16 !important;
}

.site-main-color {
    color: #0f7e16 !important;
}

.light-content-color {
    color: rgb(83, 100, 113) !important;
}

.carousel-image {
    border-radius: 1rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all .2s ease-in;
}

.carousel-image:hover, .carousel-image.active {
    border: 1px solid grey;
}

em-emoji-picker {
    height: 300px !important;
}