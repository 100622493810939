
.details-key {
    width: 35%;
    color: #6f6d6d;
    font-family: "URW Gothic";
}

.details-value {
    width: 65%;
    font-family: "URW Gothic";
}

.details-pair {
    display: flex;
    padding: 10px 1rem;
    cursor: pointer;
    border-left: 2px solid transparent;
}

.details-pair:hover {
    border-left: 2px solid red;
    background: #f3f0f0;
}

.product-secondary-image {
    border: 1px solid transparent;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.product-secondary-image:hover {
    border: 1px solid grey;
}