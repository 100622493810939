
.video-p2p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid pink;
    z-index: 1;
}

.video-p2p-video-player {
    background-color: black;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.video-p2p-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-p2p-blur {
    backdrop-filter: blur(10px);
}

.video-p2p-incoming-call {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-p2p-controls {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 1em;
}


.video-p2p-controls-container {
    background-color: rgb(179, 102, 249, .9);
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.video-p2p-small-frame {
    position: fixed;
    top: 20px;
    left: 20px;
    height: 170px;
    width: 300px;
    border-radius: 5px;
    border: 2px solid #b366f9;
    -webkit-box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.77);
    box-shadow: 3px 3px 15px -1px rgba(0, 0, 0, 0.77);
    z-index: 999;
}