.follow-button {
    border-radius: 1rem !important;
    text-transform: capitalize !important;
}

.is-following-button {
    background-color: #686464 !important;
}

.is-not-following-button {
    background-color: black !important;
}