.chat-message-content {
    position: relative;
    /*margin-right: .5rem;*/
    margin-bottom: .1rem;
    padding: .4rem 1rem !important;
    background: lightgray;
    border-radius: 1rem;
}

.chat-message-body-left-start {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 0 !important;
}

.chat-message-body-left-middle {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.chat-message-body-left-end {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 1rem !important;
}

.chat-message-body-right-start {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 0 !important;
}

.chat-message-body-right-middle {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.chat-message-body-right-end {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 1rem !important;
}

.chat-message-content-left-background {
    background: #dad8d8;
    color: #2a2929;
}

.chat-message-content-right-background {
    background: #3e8e43;
    color: white;
}

.chat-message-call.chat-message-content-left-background {
    background: #c8bec4;
    color: #433a3a;
}

.chat-message-call.chat-message-content-right-background {
    background: #cec9cc;
    color: black;
}

.chat-message-options-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.items-visible {
    visibility: visible !important;
}

.chat-message-options {
    visibility: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.chat-message-content-wrapper {
    display: inline-flex;
    width: 100%;
    font-size: 0;
}

.chat-message-content-wrapper:hover .chat-message-options {
    visibility: visible;
}

.deleted-message {
    color: #5a5959 !important;
    background: lightgrey !important;
    font-style: italic;
}

.chat-message-reply {
    background: #eceaea !important;
    color: #828080!important;
    border-radius: 1rem !important;
    font-style: normal;
}

.reply-to-text {
    display: block;
    text-align: left;
    font-weight: bolder !important;
    font-size: .65rem !important;
    white-space: nowrap !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.message-text {
    word-break: break-word;
    white-space: pre-wrap;
}

.chat-message-reply .message-text {
    font-size: .85rem !important;
}