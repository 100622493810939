

.post {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid lightgray;
    transition: background-color .1s ease-in;
}

.post:hover {
    background-color: #fafafa;
}

.post-header {
    width: 2.5rem !important;
}

.post-image-width {
    width: 2.5rem !important;
}

.post-body {
    margin-left: .5rem;
}