.user-info {
}

.user-info-header-wrapper {
    display: flex;
    width: 100%;
    padding: .5rem 2rem;
}

.user-info-hoverable {
    cursor: pointer;
    transition: background-color .1s ease-in;
}

/*.user-info-hoverable:hover {*/
/*    background-color: #f1efef;*/
/*}*/

.user-info-body {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
}

.user-body-header {
    display: flex;
    justify-content: space-between;
}

.user-info-title {
    font-weight: bolder !important;
    margin-right: .5rem !important;
    margin-top: .5rem !important;
}

.user-info-title-hoverable:hover {
    cursor: pointer;
    text-decoration: underline;
}

.user-info-subtitle {
    color: #4f9ec1;
}

.user-info-header {
    cursor: pointer;
    margin-top: .75rem !important;
}

.user-body-content {
    margin-top: .25rem !important;
}