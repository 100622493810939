.profile-page {
    min-height: calc(100dvh - 70px);
}

.sidebar-items {
    max-width: 18rem;
    margin-left: calc(100% - 18rem);
}

.link-item {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: .5rem 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color .1s ease-in;
}

.link-item:hover {
    background-color: #d8d6d6;
}

.profile-image {
    padding: .25rem;
    background: white;
    border-radius: 50%;
    /*transform: translateY(-50%);*/
    margin-top: -50%;
}

.profile-image-info {
    text-align: right;
    font-family: inherit;
}

.edit-profile-button {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
    background: white !important;
    color: black !important;
    border-radius: 2rem !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
    border: 1px solid lightgrey !important;
}

.edit-profile-button:hover {
    background: lightgrey !important;
    box-shadow: none !important;
}