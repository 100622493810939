

.file-uploader-container {
    border: 1px solid #c5c4c4;
    cursor: pointer;
}

.file-uploader-container:hover {
    border-color: #959191;
}

.file-uploader-component-error {
    border-color: #ff0000;
}

.file-uploader-component-error:hover {
    border-color: #ff0000;
}