
.chat-component {
    background: #fafafa;
    border-radius: 1rem 1rem 0 0;
    width: 100%;
}

.chat-header {
    display: flex;
    padding: .75rem 1rem 0;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
    height: 3.5rem;
    width: 100%;
}

.chat-header-image {
    width: 40px;
}

.chat-header-info {
    display: flex;
    justify-content: space-between !important;
    width: calc(100% - 40px);
}


.chat-boxes {
    position: fixed;
    bottom: 0 !important;
    right: 15rem !important;
    z-index: 1;
}

@media screen and (max-width: 1600px) {
    .chat-boxes {
        right: 5rem !important;
    }
}

@media screen and (max-width: 950px) {
    .chat-boxes {
        right: 1rem !important;
    }
}

.chat-box-width {
    width: 22.5rem !important;
}

.chat-box-wrapper {
    height: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
}

.chat-box-parent {
    position: absolute;
    bottom: 0;
}

.chat-box {
    margin: 0 .5rem;
}

.chat-box-body {
    display: flex !important;
    flex-direction: column !important;
    height: 400px !important;
    max-height: 100%;
    box-sizing: border-box !important;
}

.chat-box-messages {
    flex-grow: 1;
    overflow-y: scroll;
    padding: .75rem;
    max-height: 100%;
    box-sizing: border-box;
}

.chat-box-messages::-webkit-scrollbar {
    width: 7px !important;
    background: white;
    color: white;
}

/* Track */
.chat-box-messages::-webkit-scrollbar-track {
    background: white !important;
    border-radius: 5px;
}

/* Handle */
.chat-box-messages::-webkit-scrollbar-thumb {
    background: lightgrey !important;
    border-radius: 12px;
}

/* Handle on hover */
.chat-box-messages::-webkit-scrollbar-thumb:hover {
    background: grey !important;
    border-radius: 12px;
}

.chat-box-text-input {
    display: flex;
    padding-right: .25rem;
    background-color: #f1f1f1;
    margin-left: 1rem;
    margin-right: .25rem;
    border-radius: 15px;
    margin-bottom: .5rem;
}

.chat-box-text-input-field {
    padding-left: .5rem !important;
    overflow: hidden;
}
.chat-box-text-input-field fieldset {
    border: none;
}

.send-message-icon {
    color: #3e8e43 !important;
}

.no-messages {
    width: 100%;
    height: 100%;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .chat-component {
        border-radius: 0;
    }
    .chat-box {
        margin: 0 !important;
    }
    .chat-boxes {
        right: 0 !important;
    }

    .chat-box-width {
        width: 100vw !important;
    }

    .chat-box-wrapper {
        margin-left: 0;
        margin-right: 0;
        height: 100dvh !important;
    }

    .chat-box-body {
        height: calc(100dvh - 3.5rem) !important;
    }
}